<template>
    <div class="wrapper">
        <table class="custom-table">
            <tbody>
                <!-- 国家级统一招标重点工程 -->
                <tr>
                    <td colspan="7">
                        <template v-for="(v,index) in data.Project">
                            <table :key="index" class="custom-table">
                                <tbody class="border6">
                                    <tr>
                                        <td
                                            rowspan="22"
                                            class="custom-th classify border-top-none"
                                            :class="{'custom-th-odd':index%2===1,'custom-th-even':index%2===0}"
                                        >
                                            {{ v.project.project_type }}
                                        </td>
                                        <td colspan="2" class="custom-th border-top-none">
                                            商混销量（万方）
                                        </td>
                                        <td colspan="2" class="border-top-none">
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                class="edit-input"
                                                v-model="v.project.sales_concrete"
                                                :class="{'red': parseFloat(v.project.sales_concrete)<0}"
                                                @blur="validateFn(v.project.sales_concrete)"
                                            ></el-input>
                                        </td>
                                        <td class="custom-th border-top-none">
                                            商混售价（元/方）
                                        </td>
                                        <td class="border-top-none">
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                class="edit-input"
                                                v-model="v.project.price_concrete"
                                                :class="{'red': parseFloat(v.project.price_concrete)<0}"
                                                @blur="validateFn(v.project.price_concrete)"
                                            ></el-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="custom-th">
                                            砂浆销量（万吨）
                                        </td>
                                        <td colspan="2">
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                class="edit-input"
                                                v-model="v.project.sales_mortar"
                                                :class="{'red': parseFloat(v.project.sales_mortar)<0}"
                                                @blur="validateFn(v.project.sales_mortar)"
                                            ></el-input>
                                        </td>
                                        <td class="custom-th">
                                            砂浆售价（元/吨）
                                        </td>
                                        <td>
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                class="edit-input"
                                                v-model="v.project.price_mortar"
                                                :class="{'red': parseFloat(v.project.price_mortar)<0}"
                                                @blur="validateFn(v.project.price_mortar)"
                                            ></el-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="7" class="custom-th" style="width: 1rem;">
                                            货款回收
                                        </td>
                                        <td class="custom-th" style="width:1.6rem;"></td>
                                        <td class="custom-th" style="width:2rem">
                                            本月
                                        </td>
                                        <td class="custom-th" style="width:2rem">
                                            本年累计
                                        </td>
                                        <td class="custom-th">
                                            本年计划
                                        </td>
                                        <td class="custom-th">
                                            本年计划完成率
                                        </td>
                                    </tr>
                                    <template v-for="(term, termIndex) in v.projectDetail">
                                        <tr :key="'term'+`${termIndex}`" v-if="termIndex==0">
                                            <td class="custom-th">
                                                {{ term.type_name }}
                                            </td>
                                            <td :class="{'red': parseFloat(term.sales)<0}">
                                                {{ term.sales }}
                                            </td>
                                            <td :class="{'red': parseFloat(term.sales_year)<0}">
                                                {{ term.sales_year }}
                                            </td>
                                            <td>
                                                <el-input
                                                    oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                    :disabled="type=='approval'"
                                                    class="edit-input"
                                                    v-model="term.plan_year"
                                                    @blur="computProject('plan_year',term,index)"
                                                    :class="{'red': parseFloat(term.plan_year)<0}"
                                                ></el-input>
                                            </td>
                                            <td :class="{'red': parseFloat(term.plan_year_finish)<0}">
                                                {{ term.plan_year_finish }}
                                            </td>
                                        </tr>
                                        <tr :key="'term'+`${termIndex}`" v-else-if="termIndex==1">
                                            <td class="custom-th">
                                                {{ term.type_name }}
                                            </td>
                                            <td :class="{'red': parseFloat(term.sales)<0}">
                                                {{ term.sales }}
                                            </td>
                                            <td :class="{'red': parseFloat(term.sales_year)<0}">
                                                {{ term.sales_year }}
                                            </td>
                                            <td :class="{'red': parseFloat(term.plan_year)<0}">
                                                {{ term.plan_year }}
                                            </td>
                                            <td :class="{'red': parseFloat(term.plan_year_finish)<0}">
                                                {{ term.plan_year_finish }}
                                            </td>
                                        </tr>
                                        <tr :key="'term'+`${termIndex}`" v-else>
                                            <td class="custom-th">
                                                {{ term.type_name }}
                                            </td>
                                            <td>
                                                <el-input
                                                    oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                    :disabled="type=='approval'"
                                                    class="edit-input"
                                                    v-model="term.sales"
                                                    @blur="computProject('sales',term,index)"
                                                    :class="{'red': parseFloat(term.sales)<0}"
                                                ></el-input>
                                            </td>
                                            <td :class="{'red': parseFloat(term.sales_year)<0}">
                                                {{ term.sales_year }}
                                            </td>
                                            <td>
                                                <el-input
                                                    oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                    :disabled="type=='approval'"
                                                    class="edit-input"
                                                    v-model="term.plan_year"
                                                    @blur="computProject('plan_year',term,index)"
                                                    :class="{'red': parseFloat(term.plan_year)<0}"
                                                ></el-input>
                                            </td>
                                            <td :class="{'red': parseFloat(term.plan_year_finish)<0}">
                                                {{ term.plan_year_finish }}
                                            </td>
                                        </tr>
                                    </template>
                                    <tr>
                                        <td colspan="2" rowspan="3" class="custom-th">
                                            应收帐款余额
                                        </td>
                                        <td colspan="2" class="custom-th">
                                            减少(万元)
                                        </td>
                                        <td colspan="2" :class="{'red': parseFloat(v.project.pay_balance_reduce)<0}">
                                            {{ v.project.pay_balance_reduce }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="custom-th">
                                            期初(万元)
                                        </td>
                                        <td colspan="2" :class="{'red': parseFloat(v.project.pay_balance_begin)<0}">
                                            <!-- <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                class="edit-input"
                                                v-model="v.project.pay_balance_begin"
                                                @blur="computProjectYszkye(index)"
                                                :class="{'red': parseFloat(v.project.pay_balance_begin)<0}"
                                            ></el-input> -->
                                            {{ v.project.pay_balance_begin }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="custom-th">
                                            期末(万元)
                                        </td>
                                        <td colspan="2" :class="{'red': parseFloat(v.project.pay_balance_end)<0}">
                                            <!-- <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                class="edit-input"
                                                v-model="v.project.pay_balance_end"
                                                @blur="computProjectYszkye(index)"
                                                :class="{'red': parseFloat(v.project.pay_balance_end)<0}"
                                            ></el-input> -->
                                            {{ v.project.pay_balance_end }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" rowspan="2" class="custom-th">
                                            预收账款
                                        </td>
                                        <td colspan="2" class="custom-th">
                                            期初(万元)
                                        </td>
                                        <td colspan="2">
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                class="edit-input"
                                                v-model="v.project.advance_begin"
                                                @blur="computAdvance"
                                                :class="{'red': parseFloat(v.project.advance_begin)<0}"
                                            ></el-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="custom-th">
                                            期末(万元)
                                        </td>
                                        <td colspan="2">
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                class="edit-input"
                                                v-model="v.project.advance_end"
                                                @blur="computAdvance"
                                                :class="{'red': parseFloat(v.project.advance_end)<0}"
                                            ></el-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" rowspan="2" class="custom-th">
                                            应收账款
                                        </td>
                                        <td colspan="2" class="custom-th">
                                            期初(万元)
                                        </td>
                                        <td colspan="2">
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                class="edit-input"
                                                v-model="v.project.pay_begin"
                                                @blur="computTotalPay"
                                                :class="{'red': parseFloat(v.project.pay_begin)<0}"
                                            ></el-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="custom-th">
                                            期末(万元)
                                        </td>
                                        <td colspan="2">
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                class="edit-input"
                                                v-model="v.project.pay_end"
                                                @blur="computTotalPay"
                                                :class="{'red': parseFloat(v.project.pay_end)<0}"
                                            ></el-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" rowspan="6" class="custom-th">
                                            账龄
                                        </td>
                                        <td colspan="2" class="custom-th">
                                            0-1年（含）
                                        </td>
                                        <td colspan="2">
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                class="edit-input"
                                                v-model="v.project.accountage_0"
                                                @blur="computAccountage('accountage_0')"
                                                :class="{'red': parseFloat(v.project.accountage_0)<0}"
                                            ></el-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="custom-th">
                                            1-2年（含）
                                        </td>
                                        <td colspan="2">
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                class="edit-input"
                                                v-model="v.project.accountage_1"
                                                @blur="computAccountage('accountage_1')"
                                                :class="{'red': parseFloat(v.project.accountage_1)<0}"
                                            ></el-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="custom-th">
                                            2-3年（含）
                                        </td>
                                        <td colspan="2">
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                class="edit-input"
                                                v-model="v.project.accountage_2"
                                                @blur="computAccountage('accountage_2')"
                                                :class="{'red': parseFloat(v.project.accountage_2)<0}"
                                            ></el-input>
                                        </td>
                                    </tr>
                                    <tr :key="index">
                                        <td colspan="2" class="custom-th">
                                            3-4年（含）
                                        </td>
                                        <td colspan="2">
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                class="edit-input"
                                                v-model="v.project.accountage_3"
                                                @blur="computAccountage('accountage_3')"
                                                :class="{'red': parseFloat(v.project.accountage_3)<0}"
                                            ></el-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="custom-th">
                                            4-5年（含）
                                        </td>
                                        <td colspan="2">
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                class="edit-input"
                                                v-model="v.project.accountage_4"
                                                @blur="computAccountage('accountage_4')"
                                                :class="{'red': parseFloat(v.project.accountage_4)<0}"
                                            ></el-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="custom-th">
                                            5年以上
                                        </td>
                                        <td colspan="2">
                                            <el-input
                                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                                :disabled="type=='approval'"
                                                class="edit-input"
                                                v-model="v.project.accountage_5"
                                                @blur="computAccountage('accountage_5')"
                                                :class="{'red': parseFloat(v.project.accountage_5)<0}"
                                            ></el-input>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                    </td>
                </tr>
                <!-- 总计 -->
                <tr>
                    <td rowspan="20" class="custom-th classify custom-th-even">
                        总计
                    </td>
                    <td rowspan="7" class="custom-th" style="width:1rem;">
                        货款回收
                    </td>
                    <td class="custom-th" style="width:1.6rem;"></td>
                    <td class="custom-th" style="width:2rem">
                        本月
                    </td>
                    <td class="custom-th" style="width:2rem">
                        本年累计
                    </td>
                    <td class="custom-th">
                        本年计划
                    </td>
                    <td class="custom-th">
                        计划完成率(%)
                    </td>
                </tr>
                <template v-for="(item, index) in data.total.totalDetail">
                    <tr :key="'totalDetail'+index" v-if="index===0||index===1">
                        <td class="custom-th">
                            {{ item.type_name }}
                        </td>
                        <td :class="{'red': parseFloat(item.sales)<0}">
                            {{ item.sales }}
                        </td>
                        <td :class="{'red': parseFloat(item.sales_year)<0}">
                            {{ item.sales_year }}
                        </td>
                        <td v-if="index===0">
                            <el-input
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :disabled="type=='approval'"
                                class="edit-input"
                                v-model="item.plan_year"
                                @blur="computTotalData('plan_year', item)"
                                :class="{'red': parseFloat(item.plan_year)<0}"
                            ></el-input>
                        </td>
                        <td v-if="index===1" :class="{'red': parseFloat(item.plan_year)<0}">
                            {{ item.plan_year }}
                        </td>
                        <td :class="{'red': parseFloat(item.plan_year_finish)<0}">
                            {{ item.plan_year_finish }}
                        </td>
                    </tr>
                    <tr :key="'totalDetail'+index" v-else>
                        <td class="custom-th">
                            {{ item.type_name }}
                        </td>
                        <td :class="{'red': parseFloat(item.sales)<0}">
                            {{ item.sales }}
                        </td>
                        <td :class="{'red': parseFloat(item.sales_year)<0}">
                            {{ item.sales_year }}
                        </td>
                        <td>
                            <!-- <el-input
                                oninput="value=value.replace(/[^(\-?\d+\.?\d+)]/g,'')"
                                :disabled="type=='approval'"
                                class="edit-input"
                                v-model="item.plan_year"
                                @blur="computTotalData('plan_year', item)"
                                :class="{'red': parseFloat(item.plan_year)<0}"
                            ></el-input> -->
                            {{ item.plan_year }}
                        </td>
                        <td :class="{'red': parseFloat(item.plan_year_finish)<0}">
                            {{ item.plan_year_finish }}
                        </td>
                    </tr>
                </template>
                <tr>
                    <td colspan="2" rowspan="3" class="custom-th">
                        应收帐款余额
                    </td>
                    <td colspan="2" class="custom-th">
                        减少(万元)
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.total.total.pay_balance_reduce)<0}">
                        {{ data.total.total.pay_balance_reduce }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        期初(万元)
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.total.total.pay_balance_begin)<0}">
                        {{ data.total.total.pay_balance_begin }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        期末(万元)
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.total.total.pay_balance_end)<0}">
                        {{ data.total.total.pay_balance_end }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" rowspan="2" class="custom-th">
                        预收账款
                    </td>
                    <td colspan="2" class="custom-th">
                        期初(万元)
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.total.total.advance_begin)<0}">
                        {{ data.total.total.advance_begin }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        期末(万元)
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.total.total.advance_end)<0}">
                        {{ data.total.total.advance_end }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" rowspan="2" class="custom-th">
                        应收账款
                    </td>
                    <td colspan="2" class="custom-th">
                        期初(万元)
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.total.total.pay_begin)<0}">
                        {{ data.total.total.pay_begin }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        期末(万元)
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.total.total.pay_end)<0}">
                        {{ data.total.total.pay_end }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" rowspan="6" class="custom-th">
                        账龄
                    </td>
                    <td colspan="2" class="custom-th">
                        0-1年（含）
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.total.total.accountage_0)<0}">
                        {{ data.total.total.accountage_0 }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        1-2年（含）
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.total.total.accountage_1)<0}">
                        {{ data.total.total.accountage_1 }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        2-3年（含）
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.total.total.accountage_2)<0}">
                        {{ data.total.total.accountage_2 }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        3-4年（含）
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.total.total.accountage_3)<0}">
                        {{ data.total.total.accountage_3 }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        4-5年（含）
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.total.total.accountage_4)<0}">
                        {{ data.total.total.accountage_4 }}
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="custom-th">
                        5年以上
                    </td>
                    <td colspan="2" :class="{'red': parseFloat(data.total.total.accountage_5)<0}">
                        {{ data.total.total.accountage_5 }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    components: {},
    props: {
        reportMonth: {
            type: [Object],
        },
        type: {
            type: [String],
        },
        loadObj: {
            type: [Object],
        },
        typeList: {
            type: [Array],
        },
    },
    data() {
        return {
            data: {
                total: {
                    total: {},
                    totalDetail: [],
                },
                Project: [],
            },
            oldData: {},
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 获取指定年月的报表实例信息
        getInstance() {
            return this.$axios.get('/interfaceApi/report/instance/get/yyqk/'
            + this.reportMonth.report_year + '/' + (this.reportMonth.report_month - 1));
        },
        // 获取上月概况
        getPreMonPayback(instanceRes) {
            return this.$axios.get('/interfaceApi/report/payback/' + instanceRes.report_id);
        },
        // 获取产销本月情况
        getSales() {
            return this.$axios.get('/interfaceApi/report/sales/' + this.reportMonth.report_id);
        },
        // 获取-回款情况
        getPayback() {
            this.$axios
                .get('/interfaceApi/report/payback/' + this.reportMonth.report_id)
                .then(async res => {
                    if (res) {
                        this.data = res;
                        this.oldData = JSON.parse(JSON.stringify(res));
                        if (this.type !== 'approval') {
                            const salesRes = await this.getSales();
                            if (salesRes) {
                                this.data.total.total.sales_concrete = salesRes.total.concrete_sales;
                                this.data.total.total.price_concrete = salesRes.total.concrete_price;
                                this.data.total.total.sales_mortar = salesRes.total.mortar_sales;
                                this.data.total.total.price_mortar = salesRes.total.mortar_price;
                            }
                            if ( this.typeList[2].state === 0) {
                                if (this.reportMonth.report_month > 1) {
                                    const instanceRes = await this.getInstance();
                                    // eslint-disable-next-line max-depth
                                    if (instanceRes) {
                                    // eslint-disable-next-line max-depth
                                        if (instanceRes.report_state === 3) {
                                            const preMonRes = await this.getPreMonPayback(instanceRes);
                                            // eslint-disable-next-line max-depth
                                            if (preMonRes) {
                                                res.Project.map((item, index) => {
                                                    item.project.advance_begin = preMonRes.Project[index].project.advance_end;
                                                    item.project.pay_begin = preMonRes.Project[index].project.pay_end;
                                                    item.projectDetail.map((v, vIndex) => {
                                                        v.plan_year = preMonRes.Project[index].projectDetail[vIndex].plan_year;
                                                    });
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 校验销量和售价是否为负数
        validateFn(fieldItem) {
            if (fieldItem || fieldItem === 0) {
                if (parseFloat(fieldItem) < 0) {
                    this.$message.error('输入不能为负数!');
                    return;
                }
            }
        },
        // 保存-回款情况
        save() {
            const newData = {
                total: {},
                totalDetail: [],
                Project: [],
                projectDetail: [],
            };
            const totalB = util.Compare(this.oldData.total.total, this.data.total.total);
            if (!totalB) {
                newData.total = this.data.total.total;
            } else {
                newData.total = null;
            }
            const _this = this;
            const totalDetail = [];
            this.oldData.total.totalDetail.map((item, index) => {
                const boo = util.Compare(item, _this.data.total.totalDetail[index]);
                if (!boo) {
                    totalDetail.push(_this.data.total.totalDetail[index]);
                }
            });
            if (totalDetail.length > 0) {
                newData.totalDetail = totalDetail;
            }
            this.oldData.Project.map((item, index) => {
                const boo = util.Compare(item.project, _this.data.Project[index].project);
                if (!boo) {
                    newData.Project.push(_this.data.Project[index].project);
                }
                item.projectDetail.map((v, vIndex) => {
                    const boo = util.Compare(v, _this.data.Project[index].projectDetail[vIndex]);
                    if (!boo) {
                        newData.projectDetail.push(_this.data.Project[index].projectDetail[vIndex]);
                    }
                });
            });
            if (newData.Project.length <= 0) {
                newData.Project = null;
            }
            if (newData.projectDetail.length <= 0) {
                newData.projectDetail = null;
            }
            if (newData.totalDetail.length <= 0) {
                newData.totalDetail = null;
            }
            if (!newData.total && !newData.Project && !newData.projectDetail && !newData.totalDetail) {
                this.$message.warning('暂无修改内容！');
                this.$emit('save-end', 'sales', 'validateFailed');
                return;
            }
            // 判断重点一般工程销量、售价是否存在负数
            let isExistMinus = false;
            if (newData.Project) {
                newData.Project.map(item => {
                    if (!item.sales_concrete) {
                        item.sales_concrete = 0;
                    } else {
                        item.sales_concrete = parseFloat(item.sales_concrete);
                    }
                    if (item.sales_concrete < 0) {
                        isExistMinus = true;
                    }

                    if (!item.price_concrete) {
                        item.price_concrete = 0;
                    } else {
                        item.price_concrete = parseFloat(item.price_concrete);
                    }
                    if (item.price_concrete < 0) {
                        isExistMinus = true;
                    }

                    if (!item.sales_mortar) {
                        item.sales_mortar = 0;
                    } else {
                        item.sales_mortar = parseFloat(item.sales_mortar);
                    }
                    if (item.sales_mortar < 0) {
                        isExistMinus = true;
                    }

                    if (!item.price_mortar) {
                        item.price_mortar = 0;
                    } else {
                        item.price_mortar = parseFloat(item.price_mortar);
                    }
                    if (item.price_mortar < 0) {
                        isExistMinus = true;
                    }
                });
            }
            if (!isExistMinus) {
                this.$axios
                    .post('/interfaceApi/report/payback/save', newData)
                    .then(res => {
                        if (res) {
                            if (res.total) {
                                this.data.total.total = res.total;
                            }
                            if (res.totalDetail) {
                                res.totalDetail.map(item => {
                                    this.data.total.totalDetail = this.data.total.totalDetail.map(v => {
                                        if (item.sort_num === v.sort_num) {
                                            v = item;
                                        }
                                        return v;
                                    });
                                });
                            }
                            if (res.Project) {
                                res.Project.map(item => {
                                    this.data.Project = this.data.Project.map(v => {
                                        if (v.project.project_type === item.project_type) {
                                            v.project = item;
                                        }
                                        return v;
                                    });
                                });
                            }
                            if (res.projectDetail) {
                                res.projectDetail.map(item => {
                                    this.data.Project = this.data.Project.map(v => {
                                        v.projectDetail = v.projectDetail.map(detail => {
                                            if (detail.sort_num === item.sort_num && detail.project_type === item.project_type) {
                                                detail = item;
                                            }
                                            return detail;
                                        });
                                        return v;
                                    });
                                });
                            }
                            this.$message.success('保存成功');
                            this.$emit('save-end', 'payback', 'success');
                        }
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                        this.$emit('save-end', 'sales', 'error');
                    });
            } else {
                this.$message.warning('存在负数，请重新填写！');
                this.$emit('save-end', 'sales', 'validateFailed');
            }

        },
        // 本年累计销量
        getTotalYear(name) {
            return this.$axios.get('/interfaceApi/report/payback/totalyear/' + this.reportMonth.report_id + '/' + name);
        },
        // 本月总销售额
        getMonthSales() {
            return this.$axios.get('/interfaceApi/report/sales/totalsales/month/' + this.reportMonth.report_id);
        },
        // 本年总销售额
        getYearSales() {
            return this.$axios.get('/interfaceApi/report/sales/totalsales/year/' + this.reportMonth.report_id);
        },
        /*
        ** 总计--计算
        */ 
        computTotalData() {
            this.data.total.totalDetail[0].plan_year_finish = 0;
            if (parseFloat(this.data.total.totalDetail[0].sales_year) && parseFloat(this.data.total.totalDetail[0].plan_year)) {
                this.data.total.totalDetail[0].plan_year_finish = util.toFixed6(parseFloat(this.data.total.totalDetail[0].sales_year)
                / parseFloat(this.data.total.totalDetail[0].plan_year) * 100);
            } else {
                this.data.total.totalDetail[0].plan_year_finish = 0;
            }
        },
        // 计算-应收账款余额-减少
        computYszkye() {
            let pay_balance_begin = this.data.total.total.pay_balance_begin;
            let pay_balance_end = this.data.total.total.pay_balance_end;
            if (!pay_balance_begin) {
                pay_balance_begin = 0;
            }
            if (!pay_balance_end) {
                pay_balance_end = 0;
            }
            this.data.total.total.pay_balance_reduce = util.toFixed6(parseFloat(pay_balance_begin) - parseFloat(pay_balance_end));
        },
        // 计算 总计-货款回收
        async computTotalDetail(index) {
            let sumSales = 0;
            let sumYearSales = 0;
            let totalSales = 0;
            let totalYearSales = 0;
            this.data.Project.map(item => {
                if (parseFloat(item.projectDetail[index].sales)) {
                    sumSales = util.toFixed6(parseFloat(sumSales) + parseFloat(item.projectDetail[index].sales));
                }
                if (parseFloat(item.projectDetail[index].sales_year)) {
                    sumYearSales = util.toFixed6(parseFloat(sumYearSales) + parseFloat(item.projectDetail[index].sales_year));
                }
                item.projectDetail.map((v, vIndex) => {
                    if (vIndex !== 0 && vIndex !== 1) {
                        if (parseFloat(v.sales)) {
                            totalSales = util.toFixed6(parseFloat(totalSales) + parseFloat(v.sales));
                        }
                        if (parseFloat(v.sales_year)) {
                            totalYearSales = util.toFixed6(parseFloat(totalYearSales) + parseFloat(v.sales_year));
                        }
                    }
                });
            });
            if (sumSales) {
                this.data.total.totalDetail[index].sales = sumSales;
            } else {
                this.data.total.totalDetail[index].sales = 0;
            }
            if (sumYearSales) {
                this.data.total.totalDetail[index].sales_year = sumYearSales;
            } else {
                this.data.total.totalDetail[index].sales_year = 0;
            }
            if (totalSales) {
                this.data.total.totalDetail[1].sales = totalSales;
            } else {
                this.data.total.totalDetail[1].sales = 0;
            }
            if (totalYearSales) {
                this.data.total.totalDetail[1].sales_year = totalYearSales;
            } else {
                this.data.total.totalDetail[1].sales_year = 0;
            }
            // 货款回收率
            if (this.data.total.totalDetail[1].sales) {
                const res = await this.getMonthSales();
                if (res) {
                    this.data.total.totalDetail[0].sales = util.toFixed6(this.data.total.totalDetail[1].sales / (res * 1.03) * 100);
                } else {
                    this.data.total.totalDetail[0].sales = 0;
                }
            } else {
                this.data.total.totalDetail[0].sales = 0;
            }
            if (this.data.total.totalDetail[1].sales_year ) {
                const res = await this.getYearSales();
                if (res) {
                    this.data.total.totalDetail[0].sales_year = util.toFixed6(this.data.total.totalDetail[1].sales_year / (res * 1.03) * 100);
                } else {
                    this.data.total.totalDetail[0].sales_year = 0;
                }
            } else {
                this.data.total.totalDetail[0].sales_year = 0;
            }
        },
        // 计算 总计-应收账款余额
        computTotalYszkye() {
            let total_pay_balance_begin = 0;
            let total_pay_balance_end = 0;
            this.data.Project.map(item => {
                if (parseFloat(item.project.pay_balance_begin)) {
                    total_pay_balance_begin = util.toFixed6(parseFloat(total_pay_balance_begin) + parseFloat(item.project.pay_balance_begin));
                }
                if (parseFloat(item.project.pay_balance_end)) {
                    total_pay_balance_end = util.toFixed6(parseFloat(total_pay_balance_end) + parseFloat(item.project.pay_balance_end));
                }
            });
            this.data.total.total.pay_balance_begin = util.toFixed6(total_pay_balance_begin);
            this.data.total.total.pay_balance_end = util.toFixed6(total_pay_balance_end);
            this.data.total.total.pay_balance_reduce = util.toFixed6(total_pay_balance_begin - total_pay_balance_end);
        },
        // 计算 总计-预收账款
        computAdvance() {
            let total_advance_begin = 0;
            let total_advance_end = 0;
            this.data.Project.map(item => {
                if (parseFloat(item.project.advance_begin)) {
                    total_advance_begin = util.toFixed6(parseFloat(total_advance_begin) + parseFloat(item.project.advance_begin));
                }
                if (parseFloat(item.project.advance_end)) {
                    total_advance_end = util.toFixed6(parseFloat(total_advance_end) + parseFloat(item.project.advance_end));
                }
                // “应收账款余额=应收账款-预收账款”
                if (!item.project.pay_begin) {
                    item.project.pay_begin = 0;
                }
                if (!item.project.advance_begin) {
                    item.project.advance_begin = 0;
                }
                item.project.pay_balance_begin = util.toFixed6(parseFloat(item.project.pay_begin) - parseFloat(item.project.advance_begin));
                if (!item.project.pay_end) {
                    item.project.pay_end = 0;
                }
                if (!item.project.advance_end) {
                    item.project.advance_end = 0;
                }
                item.project.pay_balance_end = util.toFixed6(parseFloat(item.project.pay_end) - parseFloat(item.project.advance_end));
                if (!item.project.pay_balance_begin) {
                    item.project.pay_balance_begin = 0;
                }
                if (!item.project.pay_balance_end) {
                    item.project.pay_balance_end = 0;
                }
                item.project.pay_balance_reduce = util.toFixed6(parseFloat(item.project.pay_balance_begin)
                         - parseFloat(item.project.pay_balance_end));
            });
            this.data.total.total.advance_begin = total_advance_begin;
            this.data.total.total.advance_end = total_advance_end;
            this.computTotalYszkye();
        },
        // 计算 总计-应收账款
        computTotalPay() {
            let total_pay_begin = 0;
            let total_pay_end = 0;
            this.data.Project.map(item => {
                if (parseFloat(item.project.pay_begin)) {
                    total_pay_begin = util.toFixed6(parseFloat(total_pay_begin) + parseFloat(item.project.pay_begin));
                }
                if (parseFloat(item.project.pay_end)) {
                    total_pay_end = util.toFixed6(parseFloat(total_pay_end) + parseFloat(item.project.pay_end));
                }
                // “应收账款余额=应收账款-预收账款”
                if (!item.project.pay_begin) {
                    item.project.pay_begin = 0;
                }
                if (!item.project.advance_begin) {
                    item.project.advance_begin = 0;
                }
                item.project.pay_balance_begin = util.toFixed6(parseFloat(item.project.pay_begin) - parseFloat(item.project.advance_begin));
                if (!item.project.pay_end) {
                    item.project.pay_end = 0;
                }
                if (!item.project.advance_end) {
                    item.project.advance_end = 0;
                }
                item.project.pay_balance_end = util.toFixed6(parseFloat(item.project.pay_end) - parseFloat(item.project.advance_end));
                if (!item.project.pay_balance_begin) {
                    item.project.pay_balance_begin = 0;
                }
                if (!item.project.pay_balance_end) {
                    item.project.pay_balance_end = 0;
                }
                item.project.pay_balance_reduce = util.toFixed6(parseFloat(item.project.pay_balance_begin)
                         - parseFloat(item.project.pay_balance_end));
            });
            this.data.total.total.pay_begin = total_pay_begin;
            this.data.total.total.pay_end = total_pay_end;
            this.computTotalYszkye();
        },

        // 计算 总计-账龄
        computAccountage() {
            let sum_accountage_0 = 0;
            let sum_accountage_1 = 0;
            let sum_accountage_2 = 0;
            let sum_accountage_3 = 0;
            let sum_accountage_4 = 0;
            let sum_accountage_5 = 0;
            this.data.Project.map(item => {
                if (parseFloat(item.project.accountage_0)) {
                    sum_accountage_0 = util.toFixed6(parseFloat(sum_accountage_0) + parseFloat(item.project.accountage_0));
                }
                if (parseFloat(item.project.accountage_1)) {
                    sum_accountage_1 = util.toFixed6(parseFloat(sum_accountage_1) + parseFloat(item.project.accountage_1));
                }
                if (parseFloat(item.project.accountage_2)) {
                    sum_accountage_2 = util.toFixed6(parseFloat(sum_accountage_2) + parseFloat(item.project.accountage_2));
                }
                if (parseFloat(item.project.accountage_3)) {
                    sum_accountage_3 = util.toFixed6(parseFloat(sum_accountage_3) + parseFloat(item.project.accountage_3));
                }
                if (parseFloat(item.project.accountage_4)) {
                    sum_accountage_4 = util.toFixed6(parseFloat(sum_accountage_4) + parseFloat(item.project.accountage_4));
                }
                if (parseFloat(item.project.accountage_5)) {
                    sum_accountage_5 = util.toFixed6(parseFloat(sum_accountage_5) + parseFloat(item.project.accountage_5));
                }
            });
            this.data.total.total.accountage_0 = sum_accountage_0;
            this.data.total.total.accountage_1 = sum_accountage_1;
            this.data.total.total.accountage_2 = sum_accountage_2;
            this.data.total.total.accountage_3 = sum_accountage_3;
            this.data.total.total.accountage_4 = sum_accountage_4;
            this.data.total.total.accountage_5 = sum_accountage_5;
        },
        // 计算 总计-计划情况
        async computTotalPlanYear() {
            let totalPlanYear = 0;
            this.data.total.totalDetail.map((item, index) => {
                if (index !== 0 && index !== 1) {
                    let project1 = this.data.Project[0].projectDetail[index].plan_year;
                    let project2 = this.data.Project[1].projectDetail[index].plan_year;
                    if (!project1) {
                        project1 = 0;
                    }
                    if (!project2) {
                        project2 = 0;
                    }
                    item.plan_year = util.toFixed6(parseFloat(project1) + parseFloat(project2));
                    totalPlanYear = util.toFixed6(parseFloat(totalPlanYear) + parseFloat(item.plan_year));
                    if ( parseFloat(item.sales_year) && parseFloat(item.plan_year)) {
                        item.plan_year_finish = util.toFixed6(parseFloat(item.sales_year) / parseFloat(item.plan_year) * 100);
                    } else {
                        item.plan_year_finish = 0;
                    }
                }
            });
            this.data.total.totalDetail[1].plan_year = totalPlanYear;
            if (parseFloat(this.data.total.totalDetail[1].sales_year) && parseFloat(this.data.total.totalDetail[1].plan_year)) {
                this.data.total.totalDetail[1].plan_year_finish = util.toFixed6(parseFloat(this.data.total.totalDetail[1].sales_year)
            / parseFloat(this.data.total.totalDetail[1].plan_year) * 100);
            } else {
                this.data.total.totalDetail[1].plan_year_finish = 0;
            }
            // 货款回收率
            if (this.data.total.totalDetail[1].sales) {
                const res = await this.getMonthSales();
                if (res) {
                    this.data.total.totalDetail[0].sales = util.toFixed6(this.data.total.totalDetail[1].sales / (res * 1.03) * 100);
                } else {
                    this.data.total.totalDetail[0].sales = 0;
                }
            } else {
                this.data.total.totalDetail[0].sales = 0;
            }
            if (this.data.total.totalDetail[1].sales_year ) {
                const res1 = await this.getYearSales();
                if (res1) {
                    this.data.total.totalDetail[0].sales_year = util.toFixed6(this.data.total.totalDetail[1].sales_year / (res1 * 1.03) * 100);
                } else {
                    this.data.total.totalDetail[0].sales_year = 0;
                }
            } else {
                this.data.total.totalDetail[0].sales_year = 0;
            }
        },
        /*
        ** 重点工程、一般工程
        */
        // 本年累计回款
        getTotalYearBack(project_type, type_name) {
            return this.$axios.get('/interfaceApi/report/payback/project/totalyear/'
            + this.reportMonth.report_id + '/' + project_type + '/' + type_name);
        },
        // 本年累计销售额
        getProjectTotalSales(type) {
            return this.$axios.get('/interfaceApi/report/payback/totalsales/year/'
            + this.reportMonth.report_year + '/' + this.reportMonth.report_month + '/' + type);
        },
        // eslint-disable-next-line complexity
        async computProject(type, rowData, typeIndex) {
            const projectDetail = this.data.Project[typeIndex].projectDetail;
            const projectProject = this.data.Project[typeIndex].project;
            projectDetail[1].sales_year = 0;
            if (type === 'sales') {
                const res = await this.getTotalYearBack(rowData.project_type, rowData.type_name);
                if (res || res === 0) {
                    let total_sales = 0;
                    projectDetail.map((item, index) => {
                        if (index !== 0) {
                            if (index !== 1) {
                                if (rowData.type_name === item.type_name) {
                                    item = rowData;
                                    if (item.sales) {
                                        item.sales_year = util.toFixed6(parseFloat(res) + parseFloat(item.sales));
                                    } else {
                                        item.sales_year = util.toFixed6(parseFloat(res));
                                    }
                                    if ( parseFloat(item.sales_year) && parseFloat(item.plan_year)) {
                                        item.plan_year_finish = util.toFixed6(parseFloat(item.sales_year) / parseFloat(item.plan_year) * 100);
                                    } else {
                                        item.plan_year_finish = 0;
                                    }
                                    const _this = this;
                                    setTimeout(function () {
                                        _this.computTotalDetail(index);
                                    }, 500);
                                }
                                if (!item.sales) {
                                    item.sales = 0;
                                }
                                total_sales = util.toFixed6(parseFloat(total_sales) + parseFloat(item.sales));
                                if (parseFloat(item.sales_year)) {
                                    projectDetail[1].sales_year = util.toFixed6(parseFloat(projectDetail[1].sales_year)
                                    + parseFloat(item.sales_year));
                                }
                                if (projectDetail[1].sales_year && projectDetail[1].sales_year !== 0
                                && projectDetail[1].plan_year && projectDetail[1].plan_year !== 0) {
                                    projectDetail[1].plan_year_finish
                                    = util.toFixed6(projectDetail[1].sales_year / projectDetail[1].plan_year * 100);
                                } else {
                                    projectDetail[1].plan_year_finish = 0;
                                }
                            }

                        }
                        return item;
                    });
                    projectDetail[1].sales = total_sales;
                    // 货款回收率 - 本年

                    if (!projectDetail[1].sales_year) {
                        projectDetail[1].sales_year = 0;
                    }
                    const resProjectTotalSales = await this.getProjectTotalSales(typeIndex + 1);
                    if (resProjectTotalSales >= 0) {
                        let sumSales1 = 0;
                        // eslint-disable-next-line max-depth
                        if (projectProject.sales_concrete && projectProject.price_concrete) {
                            sumSales1 = util.toFixed6(parseFloat(sumSales1)
                            + parseFloat(projectProject.sales_concrete) * parseFloat(projectProject.price_concrete));
                        }
                        // eslint-disable-next-line max-depth
                        if (projectProject.sales_mortar && projectProject.price_mortar ) {
                            sumSales1 = util.toFixed6(parseFloat(sumSales1)
                            + parseFloat(projectProject.sales_mortar) * parseFloat(projectProject.price_mortar));
                        }
                        const resSum = sumSales1 + util.toFixed6(resProjectTotalSales);
                        // eslint-disable-next-line max-depth
                        if (resSum) {
                            projectDetail[0].sales_year = util.toFixed6(projectDetail[1].sales_year / (resSum * 1.03) * 100);
                        } else {
                            projectDetail[0].sales_year = 0;
                        }
                    }
                    // 货款回收率 - 本月
                    if (!projectDetail[1].sales) {
                        projectDetail[1].sales = 0;
                    }
                    let sumSales = 0;
                    if (projectProject.sales_concrete && projectProject.price_concrete) {
                        sumSales = util.toFixed6(parseFloat(sumSales)
                        + parseFloat(projectProject.sales_concrete) * parseFloat(projectProject.price_concrete));
                    }
                    if (projectProject.sales_mortar && projectProject.price_mortar ) {
                        sumSales = util.toFixed6(parseFloat(sumSales)
                        + parseFloat(projectProject.sales_mortar) * parseFloat(projectProject.price_mortar));
                    }
                    if (sumSales) {
                        projectDetail[0].sales = util.toFixed6(parseFloat(projectDetail[1].sales) / (sumSales * 1.03) * 100);
                    } else {
                        projectDetail[0].sales = 0;
                    }
                }
            }
            let total_sales = 0;
            let total_plan_year = 0;
            projectDetail.map((item, index) => {
                if (index !== 0) {
                    if (index !== 1) {
                        if (rowData.sort_num === item.sort_num) {
                            item = rowData;
                            if (parseFloat(item.sales) && parseFloat(item.plan_year)) {
                                item.plan_year_finish = util.toFixed6(parseFloat(item.sales) / parseFloat(item.plan_year) * 100);
                            } else {
                                item.plan_year_finish = 0;
                            }
                        }
                        if (item.sales ) {
                            total_sales = util.toFixed6(parseFloat(total_sales) + parseFloat(item.sales));
                        }
                        if (item.plan_year ) {
                            total_plan_year = util.toFixed6(parseFloat(total_plan_year) + parseFloat(item.plan_year));
                        }
                    }
                }
                if (index === 0 ) {
                    const sales_year_rate = projectDetail[0].sales_year;
                    const plan_year_rate = projectDetail[0].plan_year;
                    if (parseFloat(sales_year_rate) && parseFloat(plan_year_rate)) {
                        projectDetail[0].plan_year_finish = util.toFixed6(parseFloat(sales_year_rate) / parseFloat(plan_year_rate) * 100);
                    } else {
                        projectDetail[0].plan_year_finish = 0;
                    }
                }
                return item;
            });
            // 计算总计-本年计划
            this.computTotalPlanYear();
            projectDetail[1].sales = total_sales;
            projectDetail[1].plan_year = total_plan_year;
            const sum_sales_year = projectDetail[1].sales_year;
            const sum_plan_year = projectDetail[1].plan_year;
            if ( sum_sales_year && sum_sales_year !== 0 && sum_plan_year && sum_plan_year !== 0) {
                projectDetail[1].plan_year_finish = util.toFixed6(sum_sales_year / sum_plan_year * 100);
            } else {
                projectDetail[1].plan_year_finish = 0;
            }
            // 货款回收率
            // if (!projectDetail[1].sales) {
            //     projectDetail[1].sales = 0;
            // }
            // if (!projectProject.sales_concrete) {
            //     projectProject.sales_concrete = 0;
            // }
            // if (!projectProject.price_concrete) {
            //     projectProject.price_concrete = 0;
            // }
            // if (!projectProject.sales_mortar) {
            //     projectProject.sales_mortar = 0;
            // }
            // if (!projectProject.price_mortar) {
            //     projectProject.price_mortar = 0;
            // }
            // const sumSales = projectProject.sales_concrete * projectProject.price_concrete
            //     + projectProject.sales_mortar * projectProject.price_mortar;
            // if (sumSales) {
            //     projectDetail[0].sales = util.toFixed6(projectDetail[1].sales / (sumSales * 1.03) * 100);
            // } else {
            //     projectDetail[0].sales = 0;
            // }

        },
        // computProjectYszkye(index) {
        //     let pay_balance_begin = this.data.Project[index].project.pay_balance_begin;
        //     let pay_balance_end = this.data.Project[index].project.pay_balance_end;
        //     if (!pay_balance_begin) {
        //         pay_balance_begin = 0;
        //     }
        //     if (!pay_balance_end) {
        //         pay_balance_end = 0;
        //     }
        //     this.data.Project[index].project.pay_balance_reduce = util.toFixed6(parseFloat(pay_balance_begin) - parseFloat(pay_balance_end));
        //     this.computTotalYszkye();
        // },
    },
    created() {
        this.getPayback();
    },
    mounted() {},
};
</script>
<style lang="stylus">
</style>